<template>
  <section class="request-details">
    <Section>
      <section class="request-details__block">
        <Headline size="4">Accreditation Requested</Headline>
        <InputLayout label="Apply to">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('eventType') === 'match' ? 'Single match' : 'Season' }}</span>
        </InputLayout>
        <InputLayout label="Status">
          <span class="request-details__block-text request-details__capitalize">

            {{ getFromRequest('status') === 'in_review' ? 'In Review' : getFromRequest('status') }}
          </span>
        </InputLayout>
        <InputLayout label="Linked partner activation"
        v-if="getFromRequest('booking.bookingId') !== '--'">
          <a class="request-details__block-text request-details__capitalize link-to-activation"
             @click="redirectToActivationDetails(
               getFromRequest('booking.bookingId'),
               getFromRequest('booking')
        )">{{
              getFromRequest('booking.activity.name')
            }}</a>
        </InputLayout>
      </section>


      <section class="request-details__block">
        <Headline size="4">Event Information</Headline>
        <InputLayout label="Matchday" v-if="getFromRequest('eventType') === 'match'">
          <span class="request-details__block-text">{{ getFromRequest('event.matchday.name') }}</span>
        </InputLayout>
        <InputLayout label="Match" v-if="getFromRequest('eventType') === 'match'">
          <span class="request-details__block-text">{{ getFromRequest('event.name') }}</span>
        </InputLayout>
        <InputLayout label="Season" v-if="getFromRequest('eventType') !== 'match'">
          <span class="request-details__block-text">{{ getFromRequest('event.name') }}</span>
        </InputLayout>
        <InputLayout label="Venue" v-if="getFromRequest('eventType') === 'match'">
          <span class="request-details__block-text">{{ getFromRequest('event.venue.localTitle') }}</span>
        </InputLayout>
        <InputLayout label="Match Kick-off Time (UTC+8)" v-if="getFromRequest('eventType') === 'match'">
          <span class="request-details__block-text">{{  getFromRequest('event.startDateTime') ? this.getMatchKickoffTime(getFromRequest('event.startDateTime')) : '' }}</span>
        </InputLayout>
        <InputLayout label="Venues" v-if="getFromRequest('eventType') !== 'match'">
          <span class="request-details__block-text">
            <template v-if="getFromRequest('allVenues') === 1">
              All venues
            </template>
            <template v-else>
              {{ Array.isArray(venues) ? venues.map(venue => venue.localTitle).join(venuesGlue) : '' }}
            </template>
          </span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Organisation Information</Headline>
        <InputLayout label="Category">
          <span class="request-details__block-text">{{ getFromRequest('subcategory.category.name') }}</span>
        </InputLayout>
        <InputLayout label="Designation">
          <span class="request-details__block-text">{{ getFromRequest('subcategory.name') }}</span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Personal Information</Headline>
        <InputLayout label="First Name (as in passport)">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('person.firstName') }}</span>
        </InputLayout>
        <InputLayout label="Last Name (as in passport)">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('person.lastName') }}</span>
        </InputLayout>
        <InputLayout label="Gender">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('person.gender') }}</span>
        </InputLayout>
        <InputLayout label="Date of Birth">
          <span class="request-details__block-text">{{ moment(getFromRequest('person.birthDate')).format('DD.MM.YYYY') }}</span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Identification Information</Headline>
        <InputLayout label="Nationality">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('person.nationality.name') }}</span>
        </InputLayout>
        <InputLayout label="Passport / ID number">
          <span class="request-details__block-text">{{ getFromRequest('person.passportNumber') }}</span>
        </InputLayout>
        <InputLayout label="Passport / ID Expiry Date">
          <span class="request-details__block-text">{{ getFromRequest('person.passportExpiryDate') !== '--' ? moment(getFromRequest('person.passportExpiryDate')).format('DD.MM.YYYY') : '--' }}</span>
        </InputLayout>
        <InputLayout label="Accreditation Photo">
          <img
              class="request-details__passport-photo"
              v-if="requestDetails && requestDetails.person && requestDetails.person.accreditationPhoto"
              :src="getFromRequest('person.accreditationPhoto')"
              alt="photo"
          />
          <span v-else class="request-details__block-text">--</span>
        </InputLayout>
        <InputLayout label="Copy of Passport / ID">
          <img
              class="request-details__passport-photo"
              v-if="requestDetails && requestDetails.person && requestDetails.person.passportPhoto"
              :src="getFromRequest('person.passportPhoto')"
              alt="photo"
          />
          <span v-else class="request-details__block-text">--</span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Contact Information</Headline>
        <InputLayout label="Email">
          <span class="request-details__block-text">{{ getFromRequest('person.email') }}</span>
        </InputLayout>
        <InputLayout label="Mobile">
          <span class="request-details__block-text">{{ getFromRequest('person.phone') }}</span>
        </InputLayout>
      </section>
    </Section>
    <div class="btn-wrapper">
      <Button
          type="primary"
          @click="navigateToOverview()"
      >
        Back
      </Button>
      <Button
          type="danger"
          class="delete-button"
          @click="deleteAccreditationRequest()"
          v-if="getFromRequest('status') === 'requested'"
      >
        Delete
      </Button>
    </div>
    <vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>
  </section>
</template>

<script>
import Headline from '@/components/Headline/Headline.component';
import InputLayout from '@/components/Inputs/components/InputLayout.component';
import Section from '@/components/Section/Section.component';
import Button from "@/components/Button/Button.component";

import moment from "moment";

import get from "lodash.get";
import RouterNames from "@/router/route.names";
import {DtoApiDeleteAccreditationRequest} from "../../../../classes/dto/api/accreditations/dto.api.delete-accredittaion.request";

export default {
  components: {
    Headline,
    InputLayout,
    Section,
    Button
  },
  data: () => ({
    venuesGlue: '; ',
  }),
  computed: {
    requestDetails() { return get(this, '$store.state.accreditationsList.singleRequestDetails') },
    venues() {
      return this.getFromRequest('venues');
    },
  },
  methods: {
    async deleteAccreditationRequest() {
      this.$confirm(
          {
            message: `Are you sure you want to delete this accreditation request?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                new DtoApiDeleteAccreditationRequest().$deleteAccreditation(this.getFromRequest('accreditationId'), this.$store.state.user.account.id).then(() => {
                  this.navigateToOverview()
                })
              }
            }
          })
    },
    navigateToOverview() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequestsRoutes.AccreditationRequestList;
      this.$router.push({name});
    },
    moment,
    getFromRequest(path) {
      const data = get(this, `requestDetails.${path}`, )
      return data ? data : '--'
    },
    getSingleAccreditation(data) { this.$store.dispatch('accreditationsList/getSingleAccreditation', data) },
    redirectToActivationDetails(request) {
      if (!request) return;
      this.$router.push({
        name: RouterNames.IndexRoutes.DashboardRoutes.SponsorActivationRoutes.SponsorActivationDetails,
        params: {request}
      })
    },
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToAccreditation'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    getMatchKickoffTime(startDateTime) {
      return moment(startDateTime).add(8, 'hours').format('DD.MM.YYYY HH:mm')
    },
  },
  created() {
    this.checkPermissions();
    const accreditationId = this.$route.params.request
    this.getSingleAccreditation(accreditationId)
  }
}
</script>

<style lang="scss">
@import "../../../../styles/mixins/ellipsis.mixin";

.request-details {
  .delete-button {
    margin-left: 20px;
  }

  &__block {
    margin-bottom: 50px;
  }
  &__section,
  &__comments {
    margin-top: 60px;
  }

  .headline-component {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }

  .InputLayout {
    margin-bottom: 10px;
  }

  .InputLabel__label {
    @include ellipsis;
    $width: 250px;
    overflow: visible;
    min-width: $width;
    max-width: $width;
    width: $width;
    font-weight: 400;
    font-size: 14px;
  }

  &__passport-photo {
    max-width: 220px;
  }

  &__capitalize {
    text-transform: capitalize;
  }

  .link-to-activation:hover {
    cursor: pointer;
    text-decoration: none;
  }

  .link-to-activation {
    color: black;
    font-weight: bold;
  }
}
</style>
